@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&display=swap');


.tippy-box,
.tippy-arrow {
  background-color: #64748b;
  color: #64748b;
}

.tippy-content {
  font-family: 'Fuzzy Bubbles', cursive !important;
  color: #f1f5f9 !important;
  color: inherit;
  vertical-align: middle;
  padding-top: 0.6rem;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10,10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active::after {
  transform: scale(0,0);
  opacity: .2;
  transition: 0s;
}

#particles {
  z-index: 0 !important;
}